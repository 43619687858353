import React from 'react'

const error404 = () => {
    return (
        <div>
            <h1>Not Found!</h1>
        </div>
    )
}

export default error404
